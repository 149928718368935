import AccountIcon from '@thebeansgroup/ui/icons/account'
import MenuIcon from '@thebeansgroup/ui/icons/menu'

import { getIsLoggedIn } from '@helpers/auth'

import {
  ACCOUNT_PROFILE_PICTURE_FALLBACK,
  ACCOUNT_MENU_ICON_FALLBACK,
  MISSING_IMG
} from './constants'

export const getImgSrc = (data) => {
  const imgSrc = data?.accountsViewer?.userProfile?.avatar?.thumbnailUrl

  if (!imgSrc || imgSrc.includes(MISSING_IMG)) return ''

  return imgSrc
}

export const getIcon = (data) => {
  if (getIsLoggedIn(data)) return AccountIcon

  return MenuIcon
}

export const getLabel = (t, data) => {
  if (getIsLoggedIn(data))
    return t('y_account_profile_picture', {
      fallback: ACCOUNT_PROFILE_PICTURE_FALLBACK
    })

  return t('y_account_menu_icon', { fallback: ACCOUNT_MENU_ICON_FALLBACK })
}
