import { withScope } from '@sentry/nextjs'

import { ALGOLIA_ERROR_MESSAGE } from '@constants/report_algolia'

import { captureExceptionError } from './capture_exception_error'

export const reportAlgoliaError = (error) => {
  withScope((scope) => {
    scope.setExtras(error)
    captureExceptionError(error, { name: ALGOLIA_ERROR_MESSAGE })
  })

  return { algoliaError: true }
}
