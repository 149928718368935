import { TCountryCode } from '@typings/countries'

export const COUNTRIES: { [key in TCountryCode]: string } = {
  at: 'd_Austria',
  au: 'd_Australia',
  be: 'd_Belgium',
  ca: 'd_Canada',
  ch: 'd_Switzerland',
  cn: 'd_China',
  de: 'd_Germany',
  dk: 'd_Denmark',
  es: 'd_Spain',
  fi: 'd_Finland',
  fr: 'd_France',
  hk: 'd_Hong_Kong',
  hr: 'd_Croatia',
  ie: 'd_Ireland',
  in: 'd_India',
  it: 'd_Italy',
  mx: 'd_Mexico',
  ng: 'd_Nigeria',
  nl: 'd_Netherlands',
  no: 'd_Norway',
  nz: 'd_New_Zealand',
  pl: 'd_Poland',
  pt: 'd_Portugal',
  ro: 'd_Romania',
  se: 'd_Sweden',
  sg: 'd_Singapore',
  uk: 'd_United_Kingdom',
  us: 'd_United_States'
}

export const COUNTRIES_EN: { [key in TCountryCode]: string } = {
  at: 'Austria',
  au: 'Australia',
  be: 'Belgium',
  ca: 'Canada',
  ch: 'Switzerland',
  cn: 'China',
  de: 'Germany',
  dk: 'Denmark',
  es: 'Spain',
  fi: 'Finland',
  fr: 'France',
  hk: 'Hong Kong S.A.R.',
  hr: 'Croatia',
  ie: 'Ireland',
  in: 'India',
  it: 'Italy',
  mx: 'Mexico',
  ng: 'Nigeria',
  nl: 'Netherlands',
  no: 'Norway',
  nz: 'New Zealand',
  pl: 'Poland',
  pt: 'Portugal',
  ro: 'Romania',
  se: 'Sweden',
  sg: 'Singapore',
  uk: 'United Kingdom',
  us: 'United States'
}

export const COUNTRY_LIST_DROPDOWN_ICON_FALLBACK = 'Country list dropdown icon'
