import dynamic from 'next/dynamic'
import { Fragment, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import SearchIcon from '@thebeansgroup/ui/icons/search'
import Typography from '@thebeansgroup/ui/typography'

import { SEARCH_INPUT_PLACEHOLDER_FALLBACK } from '@constants/search'
import { handleSearchClick } from '@helpers/search'
import { useI18n } from '@hooks/use_i18n'
import { useIsSnowplowSetup } from '@hooks/use_is_snowplow_setup'
import { NullableReactElement } from '@typings/utils'

import { ICON_SIZE, SEARCH_LOCATION, INITIAL_SEARCH_ID } from './constants'
import { TSearchProps } from './types'

const SearchTakeoverAlgolia = dynamic(
  () => import('@components/search_takeover_algolia'),
  {
    ssr: false
  }
)

const Search = ({ promoBlocks = [] }: TSearchProps): React.ReactElement => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [searchId, setSearchId] = useState(INITIAL_SEARCH_ID)
  const { t } = useI18n()
  const { isSnowplowSetup } = useIsSnowplowSetup()

  const handleSearchOpen = (): void => {
    const newSearchId = uuidv4()
    setSearchId(newSearchId)
    handleSearchClick(SEARCH_LOCATION, newSearchId)
    setIsSearchOpen(true)
  }

  const handleSearchClose = (): void => {
    setSearchId(INITIAL_SEARCH_ID)
    setIsSearchOpen(false)
  }

  const renderSearchButton = (): React.ReactElement => (
    <span>
      <button
        onClick={handleSearchOpen}
        data-testid='nav-search-desktop'
        className='w-full py-3 xl:p-0'
      >
        <span className='py-4 px-5 flex items-center bg-liquorice-100 rounded-[5px] gap-4 xl:p-[13px_16px_11px_18px] [&_p]:inline-block [&_p]:whitespace-nowrap [&_p]:leading-[normal]'>
          <SearchIcon
            size={ICON_SIZE}
            label='Search Icon'
            colour='grey-400'
          />
          <Typography variant='subhead'>
            {t('d_brands_items_categories', {
              fallback: SEARCH_INPUT_PLACEHOLDER_FALLBACK
            })}
          </Typography>
        </span>
      </button>
    </span>
  )

  const renderSearchTakeover = (): NullableReactElement => {
    if (!isSearchOpen) return null

    return (
      <SearchTakeoverAlgolia
        isOpen={isSearchOpen}
        handleClose={handleSearchClose}
        searchId={searchId}
        isSnowplowSetup={isSnowplowSetup}
        promoBlocks={promoBlocks}
      />
    )
  }

  return (
    <Fragment>
      {renderSearchButton()}
      {renderSearchTakeover()}
    </Fragment>
  )
}

export default Search
