import { usePathname, useSearchParams } from 'next/navigation'
import PropTypes from 'prop-types'

import Button from '@thebeansgroup/ui/button'

import { getTheme } from '@app/_helpers/theme'
import { getSignupUrl, getRegisterText } from '@helpers/auth'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

const HeaderSignupLink = ({ isLoggedIn }) => {
  const countryCode = useCountryCode()
  const { t } = useI18n()
  const { ccg, isGradBeansPage } = useCCG()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  if (isLoggedIn) return null

  const signupUrl = getSignupUrl(countryCode, pathname, searchParams, ccg)
  const registerText = getRegisterText(t)

  return (
    <div className='flex justify-center ml-0 xl:ml-4'>
      <Button
        theme={getTheme(isGradBeansPage)}
        href={signupUrl}
        component='a'
        size='condensed'
      >
        {registerText}
      </Button>
    </div>
  )
}

HeaderSignupLink.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
}

export default HeaderSignupLink
