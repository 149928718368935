import Placeholder from '@components/placeholder'

const HeaderCategoriesNavPlaceholder = (): React.ReactElement => {
  return (
    <div className='flex items-center h-[50px] ml-4 gap-6'>
      <Placeholder
        width='90px'
        height='16px'
      />
      <Placeholder
        width='80px'
        height='16px'
      />
      <Placeholder
        width='120px'
        height='16px'
      />
      <Placeholder
        width='70px'
        height='16px'
      />
    </div>
  )
}

export default HeaderCategoriesNavPlaceholder
