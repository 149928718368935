export const FAQS_TO_LOAD = 5

export const GRADUATE_DISCOUNT_TOP_FAQS = [
  {
    question: 'Am I eligible for Grad Beans?',
    answer:
      'If you have graduated within the last 5 years from a Bachelors, Masters, apprenticeship or another recognised professional course that lasted no less than 12 months and/or completed a qualification recognised by a professional body you’ll have access to Grad Beans.'
  },
  {
    question: 'Can I have a Student Beans account and Grad Beans account?',
    answer:
      'No, you will only be able to have either a Student Beans account or a Grad Beans account. You will be able to switch between the two. For example if you have completed your degree and sign up to Grad Beans, but decide to a masters a couple of years later, you will be able to switch back to your Student Beans account if you wish.'
  },
  {
    question: 'How do I manually verify my Grad status?',
    answer:
      'To be manually verified you must provide: – completion of a Bachelors, Masters, apprenticeship or another recognised professional course – proof you have completed a course that has lasted at least 12 months – show the name of your institution where you completed your studies – shows your full name – be dated within the last 5 years'
  }
]

export const GRADUATE_HOME_FAQS = [
  {
    question: 'What is Grad Beans?',
    answer:
      'Grad Beans allows graduates to sign up and access exclusive graduate discounts.'
  },
  {
    question: 'Why should I sign up Grad Beans?',
    answer:
      'If you have graduated within the last 5 years, Grad Beans is a fantastic way to save money with exclusive Graduate offers from brands you know and love. It’s easy and free to sign up, so what have you got to lose?'
  },
  {
    question: 'How do I sign up to Grad Beans?',
    answer:
      'If you have had a Student Beans account in the past you will be able to sign into your Student Beans account and, if eligible, be able to re-verify as a graduate. If you haven’t had a Student Beans account previously you will be able to go through our manual verification process. To be verified you must provide: – completion of a Bachelors, Masters, apprenticeship or another recognised professional course – proof you have completed a course that has lasted at least 12 months – show the name of your institution where you completed your studies – shows your full name – be dated within the last 5 years'
  },
  {
    question: 'I haven’t received a verification email?',
    answer:
      'We promise we’re not spam – but check your junk mail (we won’t be offended) to make sure it’s not hiding in there. If it’s not in your junk mail you might have spelt your email incorrectly when signing up. Give signing up another go by clicking on ‘re-enter your email’ under ‘Didn’t receive an email?’ and use the correct email address: If you’re still having problems you can choose to verify yourself manually by following the instructions [here](https://help.studentbeans.com/hc/en-us/articles/360022218420)'
  },
  {
    question: 'I can’t reset my password.',
    answer:
      'First, check your spam folder, we might’ve accidentally ended up in there. If it has be sure to add hello@studentbeans.com to your contacts as this is how we’ll send you our newsletters which will show you all of our great offers. If it’s not in there drop us an email at help@studentbeans.com explaining the problem and we’ll look into it for you asap.'
  },
  {
    question: 'I want to delete my Grad Beans account.',
    answer:
      'Is it too late now to say sorry? But if you really want to delete your account all you have to do is go to ‘My Account’ and a ‘Delete Account’ button should appear in the top right-hand corner. Once we receive a request to delete your account it will be deactivated within 7 days. We know you’re probably bored of hearing about GDPR, but we take it seriously, so when your account has been deactivated we will also wipe any data we have for you.'
  },
  {
    question: 'My verification link is not working',
    answer:
      'If you’re having problems verifying there’s a few things you can do. First, try copy and pasting the verification link into your browser, rather than just clicking on it. If this doesn’t work, you can manually verify by following the instructions here. If you’re still having problems, drop us an email at help@studentbeans.com with your student email address and we’ll look into it for you.'
  },
  {
    question: 'Why am I getting an error?',
    answer:
      'If you receive an error message then we want to get this sorted for you as soon as possible. Drop us an email at help@studentbeans.com with the URL of the page you’re trying to access and some more info about what’s going wrong and we’ll look into fixing it asap.'
  },
  {
    question: 'Is there a Grad Beans app?',
    answer: 'No, there isn’t a Grad Beans app.'
  },
  {
    question: 'The graduate discount I want to use is out of codes.',
    answer:
      'Sometimes, if we have a really great offer that turns out to be super popular, we can run out of codes. If this does happen, give us an email over at help@studentbeans.com so that we can issue more codes for you to use as soon as we can.'
  },
  ...GRADUATE_DISCOUNT_TOP_FAQS
]

export const GRADUATE_HOME_SECTION_IDS = {
  DISCOUNTS: 'discounts',
  FAQS: 'faqs',
  SIGN_UP: 'sign-up'
}
