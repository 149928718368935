import { getFlagUrl } from '@components/flag/helpers'
import SafeImage from '@components/safe_image'
import { getCountryOptions } from '@helpers/countries'

import { TFlagProps } from './types'

const Flag = ({ country }: TFlagProps): React.ReactElement => {
  const { code } = getCountryOptions(country)

  return (
    <div
      data-testid='icon'
      className='inline-block overflow-hidden rounded-1 leading-[0] align-top'
    >
      <SafeImage
        alt=''
        src={getFlagUrl(code)}
        width={30}
        height={22}
      />
    </div>
  )
}

export default Flag
