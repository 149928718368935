import { usePathname, useSearchParams } from 'next/navigation'
import PropTypes from 'prop-types'

import HeaderNavLink from '@components/header_nav_link'
import { getLoginUrl, getLoginText } from '@helpers/auth'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

const HeaderLoginLink = ({ isLoggedIn }) => {
  const countryCode = useCountryCode()
  const { t } = useI18n()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { ccg } = useCCG()

  if (isLoggedIn) return null

  return (
    <HeaderNavLink
      href={getLoginUrl(countryCode, pathname, searchParams, ccg)}
      id='nav-login'
    >
      {getLoginText(t)}
    </HeaderNavLink>
  )
}

HeaderLoginLink.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
}

export default HeaderLoginLink
