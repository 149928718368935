export const MONTH_MAP = [
  { tKey: 'd_jan', englishTranslation: 'January' },
  { tKey: 'd_feb', englishTranslation: 'February' },
  { tKey: 'd_mar', englishTranslation: 'March' },
  { tKey: 'd_apr', englishTranslation: 'April' },
  { tKey: 'd_may', englishTranslation: 'May' },
  { tKey: 'd_jun', englishTranslation: 'June' },
  { tKey: 'd_jul', englishTranslation: 'July' },
  { tKey: 'd_aug', englishTranslation: 'August' },
  { tKey: 'd_sep', englishTranslation: 'September' },
  { tKey: 'd_oct', englishTranslation: 'October' },
  { tKey: 'd_nov', englishTranslation: 'November' },
  { tKey: 'd_dec', englishTranslation: 'December' }
]
