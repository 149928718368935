import { Fragment } from 'react'

import GearIcon from '@thebeansgroup/ui/icons/gear'
import LogoutIcon from '@thebeansgroup/ui/icons/logout'

import ProfileImage from '@components/profile_image'
import { logout } from '@helpers/auth'
import { getMyAccountsUrl } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import { IMG_SIZE, LOGOUT_FALLBACK, MY_ACCOUNT_FALLBACK } from './constants'

const HeaderProfileSection = () => {
  const { t } = useI18n()
  const { data } = useUserData()
  const country = useCountryCode()

  const logoutUser = (e) => logout(e, country)

  const renderUserDetails = (user) => (
    <div className='flex items-center flex-col bg-grey-100 text-grey-500 mb-4 py-5 min-h-[100px]'>
      <div className='size-[38px] relative'>
        <ProfileImage
          width={IMG_SIZE}
          height={IMG_SIZE}
        />
      </div>
      <div className='text-ellipsis whitespace-nowrap overflow-hidden max-w-[240px] mt-4 mx-auto'>
        {user?.firstName} {user?.lastName}
      </div>
    </div>
  )

  const renderUserLinks = () => (
    <Fragment>
      <a
        className='flex items-center py-4 px-5 text-grey-500 gap-4 [&_svg]:block'
        href={getMyAccountsUrl(country)}
      >
        <GearIcon size={16} />
        <span>
          {t('a_my_account', {
            fallback: MY_ACCOUNT_FALLBACK
          })}
        </span>
      </a>
      <button
        className='flex items-center py-4 px-5 bg-white-300 gap-4 border-none [&_svg]:block hover:text-grey-300'
        onClick={logoutUser}
      >
        <LogoutIcon size={16} />
        <span>
          {t('a_log_out', {
            fallback: LOGOUT_FALLBACK
          })}
        </span>
      </button>
    </Fragment>
  )

  const user = data?.accountsViewer?.userProfile
  return (
    <Fragment>
      {renderUserDetails(user)}
      {renderUserLinks()}
    </Fragment>
  )
}

export default HeaderProfileSection
