import clsx from 'clsx'

import { useCCG } from '@hooks/use_ccg'

import { THeaderNavLinkProps } from './types'

const HeaderNavLink = ({
  children,
  href,
  id
}: THeaderNavLinkProps): React.ReactElement => {
  const { isGradBeansPage } = useCCG()

  return (
    <a
      className={clsx(
        'flex items-center font-gibson px-4 text-[16px] leading-[36px] whitespace-normal',
        !isGradBeansPage && 'hover:text-violet-500',
        isGradBeansPage && 'hover:text-huckleberry-300'
      )}
      href={href}
      data-testid={id}
    >
      {children}
    </a>
  )
}

export default HeaderNavLink
