import aa from 'search-insights'

import ThirdPartyTracking from '@thebeansgroup/third_party_tracking'

import { trackUnstructEvent } from '@src/tracker/snowplow'

import { SEARCH_CLICK_SCHEMA } from '@constants/search'
import { getNextRoute } from '@helpers/routes'

export const getOfferLink = (
  countryCode,
  brandSlug,
  offerSlug,
  suffix,
  isGradBeansPage
) => {
  if (!countryCode || !brandSlug || !offerSlug) return ''

  const parameters = {
    country: countryCode.toLowerCase(),
    brand: brandSlug,
    offer: offerSlug
  }

  if (isGradBeansPage) {
    return (
      getNextRoute('grad.brand', parameters) + getUrlParams(suffix, offerSlug)
    )
  }

  return (
    getNextRoute('brand.show', parameters) + getUrlParams(suffix, offerSlug)
  )
}

export const getUserToken = (sbid) => {
  if (sbid) return sbid

  let userToken = null

  aa('getUserToken', null, (err, newUserToken) => {
    if (err) return

    userToken = newUserToken
  })

  return userToken
}

export const handleSearchClick = (location, searchId) => {
  const data = {
    location,
    search_id: searchId
  }
  trackUnstructEvent(SEARCH_CLICK_SCHEMA, data)
  ThirdPartyTracking.sessionReplay('?search_takeover')
}

export const getUrlParams = (suffix, offerSlug) =>
  suffix ? `${suffix}&offer=${offerSlug}` : `?offer=${offerSlug}`

export const isNewLabelEnabled = (enabled, loading) => {
  return enabled && !loading
}
