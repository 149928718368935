export const getOpenColour = (isGradBeansPage: boolean): string => {
  if (isGradBeansPage) return 'text-huckleberry-400'

  return 'text-violet-500'
}

export const getOpenStrokeColour = (isGradBeansPage: boolean): string => {
  if (isGradBeansPage) return 'stroke-huckleberry-400'

  return 'stroke-violet-500'
}

export const getHoverAndActiveColour = (isGradBeansPage: boolean): string => {
  if (isGradBeansPage)
    return 'group hover:text-huckleberry-300 active:text-huckleberry-400'

  return 'group hover:text-lavender-500 active:text-violet-500'
}

export const getHoverAndActiveStrokeColour = (
  isGradBeansPage: boolean
): string => {
  if (isGradBeansPage)
    return 'group-hover:stroke-huckleberry-300 group-active:stroke-huckleberry-400'

  return 'group-hover:stroke-lavender-500 group-active:stroke-violet-500'
}
