'use client'

import dynamic from 'next/dynamic'

import Grid from '@components/grid'
import HeaderCategoriesNavPlaceholder from '@components/header_categories_nav/placeholder'
import HeaderTop from '@components/header_top'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useFlag } from '@hooks/use_flag'
import { TCountryCode } from '@typings/countries'
import { NullableReactElement } from '@typings/utils'

import { CURRENT_EVENT_NAV_TOGGLE } from './constants'
import { THeaderProps } from './types'

/* istanbul ignore next */
const HeaderCategoriesNav = dynamic(
  () => import('@components/header_categories_nav'),
  {
    loading: () => <HeaderCategoriesNavPlaceholder />,
    ssr: false
  }
)

const Header = ({ promoBlocks = [] }: THeaderProps): React.ReactElement => {
  const [eventNavToggleFlag, isEventNavToggleLoading] = useFlag<boolean>(
    CURRENT_EVENT_NAV_TOGGLE,
    false
  )
  const country = useCountryCode()
  const { isGradBeansPage } = useCCG()

  const renderCategories = (
    country: TCountryCode,
    isEventNavToggleLoading: boolean,
    eventNavToggleValue: boolean
  ): React.ReactElement => {
    if (isEventNavToggleLoading) return <HeaderCategoriesNavPlaceholder />

    return (
      <HeaderCategoriesNav
        country={country}
        eventNavToggle={eventNavToggleValue}
      />
    )
  }

  const renderCategoriesRow = (
    isGradBeansPage: boolean,
    country: TCountryCode,
    isEventNavToggleLoading: boolean,
    eventNavToggleValue: boolean
  ): NullableReactElement => {
    if (isGradBeansPage) return null

    return (
      <Grid>
        {renderCategories(
          country,
          isEventNavToggleLoading,
          eventNavToggleValue
        )}
      </Grid>
    )
  }

  return (
    <header
      role='banner'
      className='relative z-4 bg-white-300 max-w-full shadow-nav'
    >
      <div className='w-full max-w-[1250px] min-w-[320px] my-0 mx-auto'>
        <HeaderTop
          country={country}
          promoBlocks={promoBlocks}
        />
        {renderCategoriesRow(
          isGradBeansPage,
          country,
          isEventNavToggleLoading,
          eventNavToggleFlag.getValue()
        )}
      </div>
    </header>
  )
}

export default Header
