import PropTypes from 'prop-types'

import NotificationIcon from '@thebeansgroup/ui_icons/notification_icon'

const HeaderProfileMenuItem = ({
  label,
  href,
  onClick,
  hasNotification,
  id
}) => {
  const renderNotificationIcon = () => {
    if (!hasNotification) return null

    return (
      <div
        role='presentation'
        className='absolute top-[-5px] left-[-5px]'
      >
        <NotificationIcon />
      </div>
    )
  }

  return (
    <li>
      <a
        className='block py-3 px-4 text-grey-500 hover:bg-liquorice-100 hover:text-grey-500'
        href={href}
        onClick={onClick}
        data-testid={id}
      >
        <span className='relative block'>
          {renderNotificationIcon()}
          {label}
        </span>
      </a>
    </li>
  )
}

/* istanbul ignore next */
HeaderProfileMenuItem.defaultProps = {
  hasNotification: false,
  id: '',
  onClick: () => {},
  href: ''
}

HeaderProfileMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  hasNotification: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired
}

export default HeaderProfileMenuItem
