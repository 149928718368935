import PropTypes from 'prop-types'

import IconWithNotification from '@thebeansgroup/ui_icons/icon_with_notification'

import SafeImage from '@components/safe_image'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import { getImgSrc, getIcon, getLabel } from './helpers'
import ProfileImagePlaceholder from './placeholder'

const ProfileImage = ({ height, width }) => {
  const { data, loading } = useUserData()
  const { t } = useI18n()

  const renderImage = () => {
    const PROFILE_IMG = getImgSrc(data)

    if (!PROFILE_IMG) return null

    return (
      <div className='absolute top-0 right-0'>
        <SafeImage
          className='rounded-full block'
          src={PROFILE_IMG}
          alt={t('users.account.navigation.profile')}
          width={parseInt(width)}
          height={parseInt(height)}
        />
      </div>
    )
  }

  if (loading) {
    return (
      <ProfileImagePlaceholder
        width={width}
        height={height}
      />
    )
  }
  return (
    <div
      className='relative flex [&_svg]:block'
      data-testid='profile-img'
    >
      <IconWithNotification
        icon={getIcon(data)}
        label={getLabel(t, data)}
        width={width}
        height={height}
      />
      {renderImage()}
    </div>
  )
}

ProfileImage.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
}

export default ProfileImage
