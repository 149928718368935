import HeaderNavLink from '@components/header_nav_link'
import { getBlogHref } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

const HeaderBlogLink = () => {
  const countryCode = useCountryCode()
  const { t } = useI18n()
  const blogHref = getBlogHref(countryCode)

  if (!blogHref) return null

  return (
    <HeaderNavLink
      href={blogHref}
      id='nav-blog'
    >
      {t('a_blog')}
    </HeaderNavLink>
  )
}

export default HeaderBlogLink
