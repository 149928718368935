import { Fragment } from 'react'

import Placeholder from '@components/placeholder'

const HeaderSecondaryNavPlaceholder = () => {
  const mobilePlaceholder = () => (
    <div className='block justify-self-end mr-4 xl:hidden'>
      <Placeholder
        width='120px'
        height='36px'
      />
    </div>
  )

  const desktopPlaceholder = () => (
    <div className='hidden justify-self-end xl:block xl:mr-5'>
      <Placeholder
        width='415px'
        height='16px'
      />
    </div>
  )

  return (
    <Fragment>
      {mobilePlaceholder()}
      {desktopPlaceholder()}
    </Fragment>
  )
}

export default HeaderSecondaryNavPlaceholder
