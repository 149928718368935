import { MONTH_MAP } from '@constants/time'
import { TTranslateFunction } from '@typings/translations'

export const getTimeNowSeconds = (): number => Math.floor(Date.now() / 1000)

export const getReadableEndTime = (
  dateString: string,
  t: TTranslateFunction
): string => {
  const date = new Date(dateString)

  const hours = date.getHours()
  const mins = pad(date.getMinutes())

  return calculateTime(hours, mins, t) + calculateDate(date, t)
}

export const calculateDate = (endDate: Date, t: TTranslateFunction): string => {
  if (endDateIsToday(endDate)) return ''

  return `, ${endDate.getDate()} ${getMonthFromMap(
    endDate,
    t
  )} ${endDate.getFullYear()}`
}

export const getMonthFromMap = (
  endDate: Date,
  t: TTranslateFunction
): string => {
  const { tKey, englishTranslation } = MONTH_MAP[endDate.getMonth()]
  return t(tKey, { fallback: englishTranslation })
}

export const endDateIsToday = (endDate: Date): boolean => {
  const today = new Date()

  return endDate.toDateString() === today.toDateString()
}

export const calculateTime = (
  hours: number,
  mins: string,
  t: TTranslateFunction
): string => {
  if (hours > 12) {
    hours -= 12
    return t('w_pm', {
      hours: String(hours),
      mins,
      fallback: `${hours}:${mins}pm`
    })
  }

  if (hours === 0) {
    hours += 12
    return t('w_am', {
      hours: String(hours),
      mins,
      fallback: `${hours}:${mins}am`
    })
  }

  if (hours === 12) {
    return t('w_pm', {
      hours: String(hours),
      mins,
      fallback: `${hours}:${mins}pm`
    })
  }

  return t('w_am', {
    hours: String(hours),
    mins,
    fallback: `${hours}:${mins}am`
  })
}

export const pad = (str: string | number): string =>
  str.toString().padStart(2, '0')
