import { THEME } from '@app/_constants/theme'
import { TTheme } from '@typings/theme'

export const getTheme = (isGradBeansPage: boolean): TTheme =>
  isGradBeansPage ? THEME.gradbeansTheme : THEME.studentbeansTheme

export const isStudentBeansTheme = (theme: TTheme): boolean =>
  theme === THEME.studentbeansTheme

export const isGradBeansTheme = (theme: TTheme): boolean =>
  theme === THEME.gradbeansTheme

export const isBeansIdTheme = (theme: TTheme): boolean =>
  theme === THEME.beansidTheme
