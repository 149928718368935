import { IFlag, useFlagship } from '@flagship.io/react-sdk'

export const useFlag = <T>(
  flagKey: string,
  defaultValue: T
): [IFlag<T>, boolean] => {
  const { status, getFlag } = useFlagship()
  const flag = getFlag<T>(flagKey, defaultValue)

  return [flag, status.isLoading]
}
