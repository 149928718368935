import { TCountryCode } from '@typings/countries'
import { TTranslateFunction } from '@typings/translations'

import { COUNTRIES, COUNTRIES_EN } from './constants'
import { TCountryLinkPropsPosition } from './types'

export const getCountryName = (
  country: TCountryCode,
  t: TTranslateFunction
): string => {
  return t(`${COUNTRIES[country]}`, {
    fallback: COUNTRIES_EN[country]
  })
}

export const getStrokeColour = (
  position?: TCountryLinkPropsPosition
): string => {
  if (position === 'footer') return 'stroke-liquorice-300'

  return 'stroke-liquorice-500'
}

export const getHoverColour = (
  position: TCountryLinkPropsPosition,
  isGradBeansPage: boolean
): string => {
  if (position === 'footer') return 'group-hover:stroke-white-300'

  if (isGradBeansPage) return 'group-hover:stroke-huckleberry-300'

  return 'group-hover:stroke-violet-500'
}
