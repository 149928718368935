import clsx from 'clsx'
import Link from 'next/link'

import ChevronIcon from '@thebeansgroup/ui/icons/chevron'

import Flag from '@components/flag'
import { getCountryLinksHref } from '@helpers/routes'
import { useCCG } from '@hooks/use_ccg'
import { useI18n } from '@hooks/use_i18n'
import { NullableReactElement } from '@typings/utils'

import { COUNTRY_LIST_DROPDOWN_ICON_FALLBACK } from './constants'
import { getCountryName, getHoverColour, getStrokeColour } from './helpers'
import { TCountryLinkProps } from './types'

const CountryLink = ({
  country,
  position,
  displayName
}: TCountryLinkProps): React.ReactElement => {
  const { t } = useI18n()
  const { isGradBeansPage } = useCCG()

  const renderDisplayName = (): NullableReactElement => {
    const countryName = getCountryName(country, t)

    if (!displayName || !countryName) return null

    return <span className='pr-4'>{countryName}</span>
  }

  return (
    <Link
      className='group flex items-center'
      href={getCountryLinksHref(isGradBeansPage, { country })}
      data-testid={`country-link-${position}`}
    >
      {renderDisplayName()}
      <Flag country={country} />
      <ChevronIcon
        size={10}
        label={t('y_country_list_dropdown_icon', {
          fallback: COUNTRY_LIST_DROPDOWN_ICON_FALLBACK
        })}
        className={clsx(
          'rotate-90 ml-3',
          getHoverColour(position, isGradBeansPage),
          getStrokeColour(position)
        )}
      />
    </Link>
  )
}

export default CountryLink
