import clsx from 'clsx'
import { useState } from 'react'

import ChevronIcon from '@thebeansgroup/ui/icons/chevron'

import HeaderProfileMenuItem from '@components/header_profile_menu_item'
import ProfileImage from '@components/profile_image'
import { logout } from '@helpers/auth'
import { getMyAccountsUrl } from '@helpers/routes'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { NullableReactElement } from '@typings/utils'

import { IMG_SIZE, LOGOUT_FALLBACK, MY_ACCOUNT_FALLBACK } from './constants'
import {
  getOpenColour,
  getOpenStrokeColour,
  getHoverAndActiveColour,
  getHoverAndActiveStrokeColour
} from './helpers'
import { THeaderProfileMenuProps } from './types'

const HeaderProfileMenu = ({
  firstName = '',
  lastName = '',
  isLoggedIn = false
}: THeaderProfileMenuProps): NullableReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useI18n()
  const country = useCountryCode()
  const { isGradBeansPage } = useCCG()

  const renderProfileIconButton = (): React.ReactElement => {
    return (
      <button
        className={clsx(
          'flex items-center bg-none border-none',
          getHoverAndActiveColour(isGradBeansPage),
          isOpen && getOpenColour(isGradBeansPage)
        )}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        data-testid='nav-profile-menu'
      >
        <span
          className='inline-block py-0 px-3 align-sub'
          data-cs-mask
        >
          <ProfileImage
            width={IMG_SIZE}
            height={IMG_SIZE}
          />
        </span>
        <span
          className='inline mr-2'
          data-cs-mask
        >
          {`${firstName} ${lastName}`}
        </span>
        <ChevronIcon
          size={10}
          className={clsx(
            getHoverAndActiveStrokeColour(isGradBeansPage),
            isOpen && `${getOpenStrokeColour(isGradBeansPage)} -rotate-90`,
            !isOpen && 'stroke-grey-500 rotate-90'
          )}
        />
      </button>
    )
  }

  const renderProfileMenuItems = (): NullableReactElement => {
    if (!isOpen) return null

    return (
      <ol className='block absolute w-[176px] bg-white-300 z-4 right-0 py-3 px-0 shadow-1'>
        <HeaderProfileMenuItem
          href={getMyAccountsUrl(country)}
          label={t('a_my_account', {
            fallback: MY_ACCOUNT_FALLBACK
          })}
          id='nav-profile-menu-account'
        />
        <HeaderProfileMenuItem
          onClick={(e) => logout(e, country)}
          label={t('a_log_out', {
            fallback: LOGOUT_FALLBACK
          })}
          id='nav-profile-menu-logout'
        />
      </ol>
    )
  }

  if (!isLoggedIn) return null

  return (
    <nav className='inline-block relative items-center h-[30px]'>
      {renderProfileIconButton()}
      {renderProfileMenuItems()}
    </nav>
  )
}

export default HeaderProfileMenu
