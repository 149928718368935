import { Fragment } from 'react'

import SafeImage from '@components/safe_image'
import { useCCG } from '@hooks/use_ccg'

import { getLogoHref, getLogoSrc, getLogoAlt } from './helpers'
import { THeaderLogoProps } from './types'

const HeaderLogo = ({
  country = 'uk'
}: THeaderLogoProps): React.ReactElement => {
  const { isGradBeansPage } = useCCG()

  const renderMobileLogo = (): React.ReactElement => (
    <a
      className='inline-block xl:hidden'
      href={getLogoHref(isGradBeansPage, country)}
      data-testid='nav-logo'
    >
      <SafeImage
        src={getLogoSrc(isGradBeansPage)}
        alt={getLogoAlt(isGradBeansPage)}
        width={152}
        height={24}
      />
    </a>
  )

  const renderDesktopLogo = (): React.ReactElement => (
    <a
      className='hidden xl:inline-block'
      href={getLogoHref(isGradBeansPage, country)}
      data-testid='nav-logo'
    >
      <SafeImage
        src={getLogoSrc(isGradBeansPage)}
        alt={getLogoAlt(isGradBeansPage)}
        width={172}
        height={36}
      />
    </a>
  )

  return (
    <Fragment>
      {renderMobileLogo()}
      {renderDesktopLogo()}
    </Fragment>
  )
}

export default HeaderLogo
