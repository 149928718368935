import { withAssetPrefix } from '@helpers/assets'
import { getNextRoute } from '@helpers/routes'
import { TCountryCode } from '@typings/countries'

import {
  GRAD_LOGO_ALT,
  GRAD_LOGO_PATH,
  SB_LOGO_ALT,
  SB_LOGO_PATH
} from './constants'

export const getLogoSrc = (isGradBeansPage: boolean): string => {
  if (isGradBeansPage) return withAssetPrefix(GRAD_LOGO_PATH)

  return withAssetPrefix(SB_LOGO_PATH)
}

export const getLogoHref = (
  isGradBeansPage: boolean,
  country: TCountryCode
): string => {
  if (isGradBeansPage) return getNextRoute('grad.home', { country })

  return getNextRoute('home', { country })
}

export const getLogoAlt = (isGradBeansPage: boolean): string => {
  if (isGradBeansPage) return GRAD_LOGO_ALT

  return SB_LOGO_ALT
}
