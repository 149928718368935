import { useState, Suspense } from 'react'

import CountryLink from '@components/country_link'
import HeaderLogo from '@components/header_logo'
import HeaderNavigationDrawer from '@components/header_navigation_drawer'
import HeaderSecondaryNav from '@components/header_secondary_nav'
import ProfileImage from '@components/profile_image'
import Search from '@components/search'
import { useI18n } from '@hooks/use_i18n'

import { IMG_SIZE, ACCOUNT_MENU_FALLBACK } from './constants'
import { THeaderTopProps } from './types'

const HeaderTop = ({
  country,
  promoBlocks = []
}: THeaderTopProps): React.ReactElement => {
  const { t } = useI18n()
  const [isOpen, setIsOpen] = useState(false)

  const openNavDrawer = (): void => setIsOpen(true)
  const closeNavDrawer = (): void => setIsOpen(false)

  const renderAccountButton = (): React.ReactElement => (
    <button
      className={`
        justify-self-start bg-none border-none outline-none py-[20px] px-5 
        xl:hidden
      `}
      onClick={openNavDrawer}
      aria-label={t('y_account_menu', { fallback: ACCOUNT_MENU_FALLBACK })}
    >
      <ProfileImage
        width={IMG_SIZE}
        height={IMG_SIZE}
      />
    </button>
  )

  const renderLogo = (): React.ReactElement => (
    <nav
      className={`
        justify-self-center translate-x-[-10px]
        xl:justify-self-start xl:flex xl:transform-none xl:m-[0_18px_0_24px]
    `}
    >
      <ul className='flex items-center leading-[0]'>
        <li>
          <HeaderLogo country={country} />
        </li>
        <li
          className={`
            hidden ml-5
            xl:list-item
        `}
        >
          <CountryLink
            country={country}
            position='header'
          />
        </li>
      </ul>
    </nav>
  )

  const renderNavDrawer = (): React.ReactElement => (
    <Suspense>
      <HeaderNavigationDrawer
        isOpen={isOpen}
        closeNavDrawer={closeNavDrawer}
      />
    </Suspense>
  )

  const renderSearch = (): React.ReactElement => (
    <div
      className={`
        flex-[1_0_100%] order-5 px-[16px]
        xl:flex-1 xl:px-[18px] xl:order-[unset]
    `}
    >
      <Search promoBlocks={promoBlocks} />
    </div>
  )

  return (
    <div
      className={`
        flex flex-row justify-between items-center flex-wrap h-[130px]
        xl:h-[70px] [&_ul]:p-0 [&_ul]:m-0 [&_ul]:list-none
    `}
    >
      {renderAccountButton()}
      {renderLogo()}
      {renderSearch()}
      <HeaderSecondaryNav />
      {renderNavDrawer()}
    </div>
  )
}

export default HeaderTop
