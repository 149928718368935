import { ALGOLIA_INDEX_SORT_MAP } from '@constants/algolia'
import { reportAlgoliaError } from '@helpers/report_algolia_error'

import { getIndex } from './algolia'
import { getNextRoute } from './routes'
import { getTimeNowSeconds } from './time'

export const getGradHomeSectionLink = (country, section) =>
  getNextRoute('grad.home.section', { country, section })

export const getFilters = (filters) => {
  if (filters.length === 0) return ''

  return filters.join(' OR ') + ' AND'
}

export const getAlgoliaFilters = (filters, countrySlug) => {
  const brandFilters = filters.filter((filter) => filter.includes('brandName:'))

  return `countryCode: ${countrySlug} AND \
    enabled: true AND \
    contentType: native_student_discount AND \
    ${getFilters(brandFilters)} \
    startDate <= ${getTimeNowSeconds()} AND \
    endDate >= ${getTimeNowSeconds()} AND \
    consumerGroups: graduate AND NOT \
    denyListedConsumerUids: ${
      process.env.NEXT_PUBLIC_DENY_LISTED_CONSUMER_UIDS
    }`
}

export const getAlgoliaResults = async (
  page,
  filters,
  countrySlug,
  sortSlug
) => {
  try {
    const algoliaIndex = getIndex(ALGOLIA_INDEX_SORT_MAP[sortSlug])
    const results = await algoliaIndex.search('', {
      filters: getAlgoliaFilters(filters, countrySlug),
      hitsPerPage: 9,
      page,
      facets: ['brandName']
    })

    return results
  } catch (error) {
    return reportAlgoliaError(error)
  }
}
