import { Fragment, Suspense } from 'react'

import HeaderBlogLink from '@components/header_blog_link'
import HeaderLoginLink from '@components/header_login_link'
import HeaderNavLink from '@components/header_nav_link'
import HeaderProfileMenu from '@components/header_profile_menu'
import HeaderSignupLink from '@components/header_signup_link'
import { GRADUATE_HOME_SECTION_IDS } from '@constants/graduate_home_page'
import { getIsLoggedIn } from '@helpers/auth'
import { getCountryOptions } from '@helpers/countries'
import { getGradHomeSectionLink } from '@helpers/graduate_home_page'
import { getNextRoute } from '@helpers/routes'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useUserData } from '@hooks/use_user_data'

import SecondaryNavPlaceholder from './placeholder'

const HeaderSecondaryNav = () => {
  const { data, loading } = useUserData()
  const country = useCountryCode()
  const { isGradBeansPage } = useCCG()

  if (loading) return <SecondaryNavPlaceholder />

  const isLoggedIn = getIsLoggedIn(data)
  const userProfile = data?.accountsViewer?.userProfile

  const renderGradHowToSignUpLink = () => {
    if (isLoggedIn) return null

    return (
      <li className='hidden xl:inline-block'>
        <HeaderNavLink
          href={getGradHomeSectionLink(
            country,
            GRADUATE_HOME_SECTION_IDS.SIGN_UP
          )}
          id='nav-sign-up'
        >
          How to sign up
        </HeaderNavLink>
      </li>
    )
  }

  const renderGraduateLinks = () => (
    <Fragment>
      {renderGradHowToSignUpLink()}
      <li className='hidden xl:inline-block'>
        <HeaderNavLink
          href={getGradHomeSectionLink(country, GRADUATE_HOME_SECTION_IDS.FAQS)}
          id='nav-faqs'
        >
          FAQs
        </HeaderNavLink>
      </li>
      <li className='hidden xl:inline-block'>
        <HeaderNavLink
          href={getNextRoute('home', { country })}
          id='nav-student'
        >
          Student Discounts
        </HeaderNavLink>
      </li>
    </Fragment>
  )

  const renderGraduateDiscountsLink = () => {
    const { hasGraduate } = getCountryOptions(country)
    if (!hasGraduate) return null

    return (
      <li className='hidden xl:inline-block'>
        <HeaderNavLink
          href={getNextRoute('grad.home', { country })}
          id='nav-graduate'
        >
          <span
            role='img'
            aria-label='graduate'
            className='mr-2'
          >
            🎓
          </span>
          Graduate Discounts
        </HeaderNavLink>
      </li>
    )
  }

  const renderStudentLinks = () => (
    <Fragment>
      {renderGraduateDiscountsLink()}
      <li className='hidden xl:inline-block'>
        <HeaderBlogLink />
      </li>
    </Fragment>
  )

  const renderLinks = () => {
    if (isGradBeansPage) return renderGraduateLinks()

    return renderStudentLinks()
  }

  return (
    <nav className='flex justify-end pr-4 xl:pr-5'>
      <ul className='flex items-center'>
        {renderLinks()}
        <li className='hidden xl:inline-block'>
          <Suspense>
            <HeaderLoginLink isLoggedIn={isLoggedIn} />
          </Suspense>
        </li>
        <li>
          <Suspense>
            <HeaderSignupLink isLoggedIn={isLoggedIn} />
          </Suspense>
        </li>
        <li className='hidden xl:inline-block'>
          <HeaderProfileMenu
            firstName={userProfile?.firstName}
            isLoggedIn={isLoggedIn}
            lastName={userProfile?.lastName}
          />
        </li>
      </ul>
    </nav>
  )
}

export default HeaderSecondaryNav
