import { usePathname, useSearchParams } from 'next/navigation'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import Button from '@thebeansgroup/ui/button'
import CloseIcon from '@thebeansgroup/ui/icons/close'

import { getTheme } from '@app/_helpers/theme'
import CountryLink from '@components/country_link'
import HeaderProfileSection from '@components/header_profile_section'
import {
  getLoginUrl,
  getLoginText,
  getSignupUrl,
  getRegisterText
} from '@helpers/auth'
import { getCountryOptions } from '@helpers/countries'
import { getBlogHref, getNextRoute } from '@helpers/routes'
import { useCCG } from '@hooks/use_ccg'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import { CLOSE_FALLBACK } from './constants'
import { getSupportHref } from './helpers'

const HeaderNavigationDrawer = ({ isOpen, closeNavDrawer }) => {
  const { t } = useI18n()
  const { data, loading } = useUserData()
  const countryCode = useCountryCode()
  const { ccg, isGradBeansPage } = useCCG()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const renderBackgroundOverlay = () => {
    if (!isOpen) return null

    return (
      <button
        className={`fixed top-0 left-0 w-full h-full bg-grey-500 opacity-0 z-10 xl:invisible ${isOpen && 'opacity-50'}`}
        onClick={closeNavDrawer}
        data-testid='overlay'
      />
    )
  }

  const renderCloseButton = () => (
    <button
      className='absolute z-1 top-0 right-0 block p-5 bg-none border-none bg-transparent xl:invisible'
      onClick={closeNavDrawer}
      data-testid='close-button'
      aria-label={t('w_close', { fallback: CLOSE_FALLBACK })}
    >
      <CloseIcon
        size={16}
        label={t('w_close', { fallback: CLOSE_FALLBACK })}
      />
    </button>
  )

  const renderSignupButton = () => {
    const registerText = getRegisterText(t)
    const signupUrl = getSignupUrl(countryCode, pathname, searchParams, ccg)

    return (
      <Button
        theme={getTheme(isGradBeansPage)}
        component='a'
        href={signupUrl}
        fullWidth
        size='condensed'
        className='text-center'
      >
        {registerText}
      </Button>
    )
  }

  const renderLoginLinks = () => (
    <div className='px-5 mt-[100px] xl:invisible'>
      {renderSignupButton()}
      <a
        className='block text-center text-grey-500 py-3 px-5 mt-4 mb-3 xl:invisible'
        href={getLoginUrl(countryCode, pathname, searchParams, ccg)}
      >
        {getLoginText(t)}
      </a>
    </div>
  )

  const renderUserSection = () => {
    if (!data?.accountsViewer?.isLoggedIn) return renderLoginLinks()

    return <HeaderProfileSection />
  }

  const renderBlogLink = () => {
    if (isGradBeansPage) return null

    const blogHref = getBlogHref(countryCode)

    if (!blogHref) return null

    return (
      <a
        className='flex items-center text-grey-500 mb-3 py-3 px-5 lg:mb-4 xl:invisible'
        href={blogHref}
      >
        {t('a_blog')}
      </a>
    )
  }

  const renderSupportLink = () => {
    if (isGradBeansPage) return null

    return (
      <a
        className='flex items-center text-grey-500 mb-3 py-3 px-5 lg:mb-4 xl:invisible'
        href={getSupportHref(countryCode)}
      >
        {t('a_support')}
      </a>
    )
  }

  const renderCountryLink = () => (
    <div className='py-3 px-5 mb-3 lg:mb-4 xl:invisible'>
      <CountryLink
        country={countryCode}
        position='header'
        displayName
      />
    </div>
  )

  const renderStudentDiscountsLink = () => (
    <a
      className='flex items-center text-grey-500 mb-3 py-3 px-5 lg:mb-4 xl:invisible'
      href={getNextRoute('home', { country: countryCode })}
    >
      Student Discounts
    </a>
  )

  const renderGraduateDiscountsLink = () => (
    <a
      className='flex items-center text-grey-500 mb-3 py-3 px-5 lg:mb-4 xl:invisible'
      href={getNextRoute('grad.home', { country: countryCode })}
    >
      Graduate Discounts
    </a>
  )

  const renderCCGLink = () => {
    const { hasGraduate } = getCountryOptions(countryCode)
    if (!hasGraduate) return null

    if (isGradBeansPage) return renderStudentDiscountsLink()

    return renderGraduateDiscountsLink()
  }

  if (loading) return null

  return (
    <Fragment>
      {renderBackgroundOverlay()}
      <nav
        className={`fixed top-0 h-full w-full max-w-[280px] transition-all duration-300 z-10 bg-white-300 ${isOpen ? 'visible left-0' : 'invisible -left-[280px]'} xl:invisible`}
      >
        {renderCloseButton()}
        {renderUserSection()}
        <hr className='m-4 lg:my-5 lg:mx-4 border-grey-25 border-[1px] xl:invisible' />
        {renderCountryLink()}
        {renderSupportLink()}
        {renderCCGLink()}
        {renderBlogLink()}
      </nav>
    </Fragment>
  )
}

HeaderNavigationDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeNavDrawer: PropTypes.func.isRequired
}

export default HeaderNavigationDrawer
